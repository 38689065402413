<template>
  <b-card
    :header="caption"
    v-if="$auth.can($stringConstants('PERMISSION_SEND_REPORT_TO_NBU'))"
  >
    <ValidationObserver ref="formUploadedReportsFilterValidation" v-slot="{ handleSubmit }">
      <b-form>
        <b-form-row>
          <b-col md="6" lg="4" xl="4">
            <b-button
              @click="getUploadedFilesList()"
              variant="primary"
              class="mb-2 flex-grow-2"
              size="sm"
              :disabled="financial_model_id === null"
            >
              <i class="fa fa-dot-circle-o"></i> Отримати перелік завантажених<br />файлів
              від НБУ
            </b-button>
          </b-col>

          <b-col cols="6">
            <ValidationProvider
              name="профіль спфм"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="financial_model_id_where"
              rules="required"
            >
              <b-form-group label="Профіль СПФМ" label-cols="12" label-cols-lg="3">
                <b-form-select
                  v-model="financial_model_id"
                  :options="meFinancialModels"
                  value-field="id"
                  text-field="name"
                  :state="setValidationState(errors, dirty, validated, valid, changed)"
                >
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-form-row>
      </b-form>
    </ValidationObserver>

    <b-table
      show-empty
      :dark="false"
      :fields="fields"
      :hover="false"
      :striped="true"
      :bordered="true"
      :fixed="true"
      responsive="sm"
      :items="uploadedReportsList"
      :busy="submitting"
    >
      <template #table-busy>
        <div style="height: 30px">
          <div class="h4 text-center position-loading">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Завантаження...</strong>
          </div>
        </div>
      </template>

      <template v-slot:cell(message)="data">
        <b-badge
          v-if="data.item.message"
          v-b-tooltip="data.item.message"
          :data-text="data.item.message"
          @click.stop.prevent="copyItem"
          class="cursor-pointer"
          variant="light"
        >
          {{ makeShortenedMessage(data.item.message) }}
        </b-badge>
      </template>

      <template #empty="scope">
        <div style="height: 30px">
          <div class="h4 text-center position-loading">Файлів не знайдено</div>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto">
        <nav>
          <div>
            <b-btn
              @click="changePage(-1)"
              :disabled="page === 1"
              class="mr-2 flex-grow-2"
            >
              Назад
            </b-btn>
            <b-btn
              @click="changePage(+1)"
              :disabled="uploadedReportsList.length === 0"
            >
              Вперед
            </b-btn>
          </div>
        </nav>
      </b-col>
      <b-col cols="auto" class="d-flex justify-content-end">
        <div>
          <b-form-radio-group
            buttons
            v-model="count"
            :options="options"
            button-variant="outline-primary"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/api";
import mixins from "@/mixins";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_REPORT"))) next();
      else next({ name: "Page404" });
    });
  },
  mixins: [mixins],
  name: "uploaded-reports-table",
  data() {
    return {
      page: 1,
      count: 10,
      uploadedReportsList: [],
      options: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "200", value: 200 },
      ],
      fields: [
        { key: "fileid", label: "ID" },
        { key: "reportname", label: "Назва" },
        {
          key: "loaddate",
          label: "Дата завантаження",
        },
        {
          key: "status",
          label: "Статус",
          formatter: (v) =>
            v ? this.statuses.find((i) => i.value === v).name : null,
        },
        { key: "reportid", label: "ID звіту" },
        { key: "message", label: "Повідомлення" },
        {
          key: "reportdate",
          label: "Звітна дата",
        },
        { key: "filename", label: "Назва файлу" },
      ],
      caption: "Звіти завантажені до НБУ",
      submitting: false,
      financial_model_id: null,
    };
  },
  computed: {
    ...mapGetters({
      statuses: "dictionary/allNbuFileStatuses",
      meFinancialModels: "auth/meFinancialModels",
    }),
  },
  methods: {
    getUploadedFilesList() {
      this.submitting = true;
      this.uploadedReportsList = [];
      const params = {
        financial_model_id: this.financial_model_id,
        page: this.page,
        count: this.count,
      };
      api
        .getUploadedFilesReports(params)
        .then((data) => {
          this.uploadedReportsList = data.data;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$snotify.error("Помилка отримання звітів. Перевірте облікові дані НБУ у картці профіля СПФМ");
          } else {
            this.$snotify.error("Помилка отримання звітів. " + error.message);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    changePage(movement) {
      this.page = this.page + movement;
    },
    copyItem(e) {
      navigator.clipboard.writeText(e.currentTarget.getAttribute("data-text"));
      this.$snotify.success("Скопійовано в буфер обміну");
    },
    makeShortenedMessage(message) {
      return (
        message.substring(0, 20) + ".." + message.substring(message.length - 2)
      );
    },
  },
  watch: {
    page: function () {
      this.getUploadedFilesList();
    },
    count: function () {
      if (this.page === 1) {
        this.getUploadedFilesList();
      }

      this.page = 1;
    },
    financialModelId: {
      immediate: true,
      handler(newVal) {
        this.financial_model_id = newVal ?? null;
        if (this.financial_model_id === null) {
          this.uploadedReportsList = [];
        }
      }
    }
  },
};
</script>
