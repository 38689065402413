<template>
  <div>
    <b-card header="Параметри звіту">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
        <b-alert :show="errors.length > 0" variant="danger">
          <h4 class="alert-heading">Виникла помилка створення звіту!</h4>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </b-alert>
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-form-row>
            <b-col md="3">
              <ValidationProvider
                name="звіт"
                v-slot="{ errors, dirty, validated, valid, changed }"
                vid="report"
                rules="required"
              >
                <b-form-group label="Звіт">
                  <b-form-select
                    v-model="selectedOptionRoute"
                    :options="reportOptions"
                    text-field="text"
                    value-field="route"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  >
                    <template #first>
                      <b-form-select-option value=""></b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="3">
              <ValidationProvider
                name="звітна дата"
                v-slot="{ errors, dirty, validated, valid, changed }"
                vid="report_date"
                rules="required"
              >
                <b-form-group label="Звітна дата">
                  <b-form-input
                    type="date"
                    v-model="form.report_date"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="3" v-if="showReportDateFromTo">
              <b-form-group label="Період звіту">
                <b-input-group>
                  <b-col sm="6" class="p-0 pr-2">
                    <ValidationProvider
                      name="початок періоду"
                      v-slot="{ errors, dirty, validated, valid, changed }"
                      vid="date_from"
                      rules="required"
                    >
                      <b-form-input
                        type="date"
                        v-model="form.date_from"
                        :state="
                          setValidationState(
                            errors,
                            dirty,
                            validated,
                            valid,
                            changed
                          )
                        "
                      />
                      <b-form-text>Початок періоду</b-form-text>
                      <b-form-invalid-feedback
                        :state="errors.length === 0"
                        v-for="(error, index) in errors"
                        v-bind:key="index"
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>

                  <b-col sm="6" class="p-0 pl-2">
                    <ValidationProvider
                      name="кінець періоду"
                      v-slot="{ errors, dirty, validated, valid, changed }"
                      vid="date_to"
                      rules="required"
                    >
                      <b-form-input
                        type="date"
                        v-model="form.date_to"
                        :state="
                          setValidationState(
                            errors,
                            dirty,
                            validated,
                            valid,
                            changed
                          )
                        "
                      />
                      <b-form-text>Кінець періоду</b-form-text>
                      <b-form-invalid-feedback
                        :state="errors.length === 0"
                        v-for="(error, index) in errors"
                        v-bind:key="index"
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <ValidationProvider
                name="Профіль СПФМ"
                v-slot="{ errors, dirty, validated, valid, changed }"
                vid="financial_model"
                :rules="{
                  required: true,
                  selected_financial_models_report_values: {
                    report: selectedOptionRoute,
                  },
                }"
              >
                <b-form-group label="Профіль СПФМ">
                  <b-form-checkbox-group
                    v-model="selectedFinancialModels"
                    :options="meFinancialModelList"
                    value-field="id"
                    text-field="name"
                    stacked
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  >
                    <b-form-invalid-feedback
                      :state="errors.length === 0"
                      v-for="(error, index) in errors"
                      v-bind:key="index"
                    >
                      {{ error }}
                    </b-form-invalid-feedback>
                  </b-form-checkbox-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col cols="4">
              <b-button
                @click="send()"
                variant="primary"
                size="sm"
                :disabled="submitting"
              >
                Згенерувати
              </b-button>
              <b-button
                variant="warning"
                class="ml-2"
                size="sm"
                @click="flushForm()"
              >
                Очистити
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-modal
      id="report2hx"
      title="Інформація з питань управління ризиками у сфері фінансового моніторингу (F2HX)"
      no-close-on-backdrop
      hide-footer
      size="lg"
      v-model="new2hxModal"
      @ok="new2hxModal = false"
    >
      <questions-modal
        @onSubmit="onSubmit"
        :prop-questions="financialModelsQuestionsAnswers"
        :prop-global-disabled="false"
      />
    </b-modal>
    <send-file-to-nbu-form />
    <uploaded-reports-table />
  </div>
</template>

<script>
import api from "@/api/api";
import { mapGetters } from "vuex";
import mixins from "@/mixins";
import QuestionsModal from "../FinancialModels/components/QuestionsModal.vue";
import SendFileToNbuForm from "@/entity/Reporting/components/SendFileToNbuForm.vue";
import UploadedReportsTable from "@/entity/Reporting/components/UploadedReportsTable.vue";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_REPORT"))) next();
      else next({ name: "Page404" });
    });
  },
  mixins: [mixins],
  name: "Report",
  components: {
    QuestionsModal,
    UploadedReportsTable,
    SendFileToNbuForm,
  },
  created() {
    this.flushForm();
  },
  data() {
    return {
      errors: [],
      selectedOptionRoute: null,
      selectedFinancialModels: [],
      form: {
        financial_model_ids: null,
        financial_model_id: null,
        date_from: null,
        date_to: null,
        report_date: null,
        data: null,
      },
      new2hxModal: false,
      submitting: false,
    };
  },
  computed: {
    ...mapGetters({
      financialModelsQuestionsAnswers:
        "dictionary/allFinancialModelsQuestionsAnswers",
      me: "auth/me",
      meFinancialModelList: "auth/meFinancialModels",
    }),
    showReportDateFromTo: function () {
      return this.selectedOptionRoute !== "f2hx";
    },
    reportOptions: function () {
      const options = [
        {
          text: "F4EX",
          route: "f4ex",
        },
        {
          text: "F2LX",
          route: "f2lx",
        },
        {
          text: "F2JX",
          route: "f2jx",
        },
        {
          text: "F2HX",
          route: "f2hx",
        },
      ];

      if (this.me.tenant.alias === "aifintech")
        options.push({
          text: "F4LX",
          route: "f4lx",
        });

      return options;
    },
    formDataForReport() {
      let formData = {};

      switch (this.selectedOptionRoute) {
        case "f4ex":
          formData = {
            report_date: this.form.report_date,
            financial_model_ids: this.form.financial_model_ids,
            date_from: this.form.date_from,
            date_to: this.form.date_to,
          };
          break;
        case "f2lx":
          formData = {
            report_date: this.form.report_date,
            financial_model_id: this.form.financial_model_id,
            date_from: this.form.date_from,
            date_to: this.form.date_to,
          };
          break;
        case "f2jx":
          formData = {
            report_date: this.form.report_date,
            financial_model_id: this.form.financial_model_id,
            date_from: this.form.date_from,
            date_to: this.form.date_to,
          };
          break;
        case "f2hx":
          formData = {
            report_date: this.form.report_date,
            financial_model_id: this.form.financial_model_id,
            data: this.form.data,
          };
          break;
        case "f4lx":
          formData = {
            report_date: this.form.report_date,
            financial_model_id: this.form.financial_model_id,
            date_from: this.form.date_from,
            date_to: this.form.date_to,
          };
          break;
        default:
          formData = { ...this.form };
          break;
      }

      return formData;
    },
  },
  watch: {
    selectedFinancialModels: {
      deep: true,
      handler(value) {
        if (value.length === 0) {
          this.form.financial_model_ids = null;
          this.form.financial_model_id = null;
        } else if (value.length === 1) {
          if (this.selectedOptionRoute === "f4ex") {
            this.form.financial_model_id = null;
            this.form.financial_model_ids = value;
          } else {
            this.form.financial_model_id = value[0];
            this.form.financial_model_ids = null;
          }
        } else {
          this.form.financial_model_ids = value;
          this.form.financial_model_id = null;
        }
      },
    },
  },
  methods: {
    send() {
      this.$refs.formValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.selectedOptionRoute === "f2hx") {
          this.new2hxModal = true;
          return;
        }

        this.onSubmit();
      });
    },
    onSubmit(data = null) {
      if (data) {
        this.form.data = data;
      }

      this.submitting = true;
      api
        .createReport(this.selectedOptionRoute, this.formDataForReport)
        .then(() => {
          this.$root.$emit("bv::hide::modal", "report2hx");
          this.$snotify.success(
            "Звіт в обробці. Після генерації звіт буде надіслано Вам на пошту."
          );
          this.flushForm();
        })
        .catch((error) => {
          this.$root.$emit("bv::hide::modal", "report2hx");
          this.submitting = false;
          this.$snotify.error("Помилка створення звіту");
          this.errors = [];
          for (let variable in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(variable)) {
              this.errors = this.errors.concat(
                error.response.data.errors[variable]
              );
            }
          }
        });
    },
    flushForm: function () {
      this.form = {
        financial_model_ids: null,
        financial_model_id: null,
        date_from: null,
        date_to: null,
        report_date: null,
        data: null,
      };
      this.submitting = false;
      this.selectedFinancialModels = [];
      this.selectedOptionRoute = null;

      requestAnimationFrame(() => {
        this.$refs.formValidation.reset();
      });
    },
  },
};
</script>
<style>
#custom-file-input {
  border-color: #20a8d8;
}

#custom-file-input:focus {
  border-color: #20a8d8;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#custom-file-input + .custom-file-label {
  border-color: #20a8d8;
}

#custom-file-input:focus + .custom-file-label {
  border-color: #20a8d8;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#custom-file-input + .custom-file-label::after {
  background-color: #20a8d8;
  color: white;
}
</style>
