<template>
  <b-card
    :header="caption"
    v-if="$auth.can($stringConstants('PERMISSION_SEND_REPORT_TO_NBU'))"
  >
    <ValidationObserver
      ref="sendFileToNbuFormValidation"
      v-slot="{ handleSubmit }"
    >
      <b-form>
        <b-form-row>
          <b-col cols="6">
            <ValidationProvider
              name="профіль спфм"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="financial_model_id_where"
            >
              <b-form-group
                label="Профіль СПФМ"
                label-cols="12"
                label-cols-lg="3"
              >
                <b-form-select
                  v-model="financial_model_id"
                  :options="meFinancialModels"
                  value-field="id"
                  text-field="name"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                >
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="6">
            <div class="d-flex mb-4">
              <b-form-file
                id="custom-file-input"
                v-model="file"
                placeholder="Оберіть xml-звіт"
                drop-placeholder="Перетягніть звіт сюди..."
                accept=".xml"
                class="mr-2 flex-grow-2"
                size="md"
                :disabled="financial_model_id === null"
              />
              <b-button
                variant="primary"
                size="sm"
                :disabled="financial_model_id === null || file === null"
                @click="sendFileReport"
              >
                <i class="fa fa-dot-circle-o"></i> Відправити звіт до НБУ
              </b-button>
            </div>
          </b-col>
        </b-form-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/api";
import mixins from "@/mixins";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_REPORT"))) next();
      else next({ name: "Page404" });
    });
  },
  mixins: [mixins],
  name: "send-file-to-nbu-form",
  data() {
    return {
      caption: "Завантаження файлу до НБУ",
      file: null,
      submitting: false,
      financial_model_id: null,
    };
  },
  computed: {
    ...mapGetters({
      statuses: "dictionary/allNbuFileStatuses",
      meFinancialModels: "auth/meFinancialModels",
    }),
  },
  methods: {
    sendFileReport() {
      this.submitting = true;
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("financial_model_id", this.financial_model_id);

      api
        .sendFileReport(formData)
        .then(() => {
          this.$snotify.success("Звіт успішно надіслано.");
          this.flushForm();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$snotify.error(
              "Помилка надсилання звіту. Перевірте облікові дані НБУ у картці профіля СПФМ"
            );
          } else {
            this.$snotify.error(
              "Помилка надсилання звіту. " + error.response.data.message
            );
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    flushForm: function () {
      this.submitting = false;
      this.financial_model_id = null;
      this.file = null;
    },
  },
};
</script>
