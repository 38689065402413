var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"header":"Параметри звіту"}},[_c('ValidationObserver',{ref:"formValidation",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('b-alert',{attrs:{"show":_vm.errors.length > 0,"variant":"danger"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Виникла помилка створення звіту!")]),_vm._l((_vm.errors),function(error,index){return _c('li',{key:index},[_vm._v(_vm._s(error))])})],2),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"звіт","vid":"report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, dirty, validated, valid, changed }){return [_c('b-form-group',{attrs:{"label":"Звіт"}},[_c('b-form-select',{attrs:{"options":_vm.reportOptions,"text-field":"text","value-field":"route","state":_vm.setValidationState(
                      errors,
                      dirty,
                      validated,
                      valid,
                      changed
                    )},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}})]},proxy:true}],null,true),model:{value:(_vm.selectedOptionRoute),callback:function ($$v) {_vm.selectedOptionRoute=$$v},expression:"selectedOptionRoute"}}),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"звітна дата","vid":"report_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, dirty, validated, valid, changed }){return [_c('b-form-group',{attrs:{"label":"Звітна дата"}},[_c('b-form-input',{attrs:{"type":"date","state":_vm.setValidationState(
                      errors,
                      dirty,
                      validated,
                      valid,
                      changed
                    )},model:{value:(_vm.form.report_date),callback:function ($$v) {_vm.$set(_vm.form, "report_date", $$v)},expression:"form.report_date"}}),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)})],1),(_vm.showReportDateFromTo)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Період звіту"}},[_c('b-input-group',[_c('b-col',{staticClass:"p-0 pr-2",attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"початок періоду","vid":"date_from","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, dirty, validated, valid, changed }){return [_c('b-form-input',{attrs:{"type":"date","state":_vm.setValidationState(
                          errors,
                          dirty,
                          validated,
                          valid,
                          changed
                        )},model:{value:(_vm.form.date_from),callback:function ($$v) {_vm.$set(_vm.form, "date_from", $$v)},expression:"form.date_from"}}),_c('b-form-text',[_vm._v("Початок періоду")]),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,true)})],1),_c('b-col',{staticClass:"p-0 pl-2",attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"кінець періоду","vid":"date_to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, dirty, validated, valid, changed }){return [_c('b-form-input',{attrs:{"type":"date","state":_vm.setValidationState(
                          errors,
                          dirty,
                          validated,
                          valid,
                          changed
                        )},model:{value:(_vm.form.date_to),callback:function ($$v) {_vm.$set(_vm.form, "date_to", $$v)},expression:"form.date_to"}}),_c('b-form-text',[_vm._v("Кінець періоду")]),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,true)})],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Профіль СПФМ","vid":"financial_model","rules":{
                required: true,
                selected_financial_models_report_values: {
                  report: _vm.selectedOptionRoute,
                },
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, dirty, validated, valid, changed }){return [_c('b-form-group',{attrs:{"label":"Профіль СПФМ"}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.meFinancialModelList,"value-field":"id","text-field":"name","stacked":"","state":_vm.setValidationState(
                      errors,
                      dirty,
                      validated,
                      valid,
                      changed
                    )},model:{value:(_vm.selectedFinancialModels),callback:function ($$v) {_vm.selectedFinancialModels=$$v},expression:"selectedFinancialModels"}},_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])}),1)],1)]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":_vm.submitting},on:{"click":function($event){return _vm.send()}}},[_vm._v(" Згенерувати ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.flushForm()}}},[_vm._v(" Очистити ")])],1)],1)],1)]}}])})],1),_c('b-modal',{attrs:{"id":"report2hx","title":"Інформація з питань управління ризиками у сфері фінансового моніторингу (F2HX)","no-close-on-backdrop":"","hide-footer":"","size":"lg"},on:{"ok":function($event){_vm.new2hxModal = false}},model:{value:(_vm.new2hxModal),callback:function ($$v) {_vm.new2hxModal=$$v},expression:"new2hxModal"}},[_c('questions-modal',{attrs:{"prop-questions":_vm.financialModelsQuestionsAnswers,"prop-global-disabled":false},on:{"onSubmit":_vm.onSubmit}})],1),_c('send-file-to-nbu-form'),_c('uploaded-reports-table')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }